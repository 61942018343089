<template>
  <div class="page__construction">
    <h1 class="title">Страница "Медиафайлы" в разработке</h1>
    <div>
      <VButton
        text="Вернуться назад"
        color="primary"
        @click.native="$router.go(-1)"
      />
    </div>
  </div>
</template>

<script>

import VButton from '@/components/simple/button/VButton'

export default {
  components: {
    VButton
  }
}
</script>
